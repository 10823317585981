<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            src="img/avatars/boss.png"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="dark">
      <strong>{{ $commons.getCurrentUser().email }}</strong>
    </CDropdownHeader>
    <CDropdownItem @click="$router.push({name: 'Settings'})">
      <CIcon name="cil-settings" /> Einstellungen
    </CDropdownItem>
    <CDropdownItem @click="$router.push({name: 'Users'})">
      <CIcon name="cil-user" /> Benutzer
    </CDropdownItem>
    <CDropdownItem @click="$router.push({name: 'Logout'})">
        <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return {
      itemsCount: 42
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
