<template>
  <!--<CFooter>
    <div>
      <a href="https://makler-anfragen.immo" target="_blank">Marketing Cloud</a>
      <span class="ml-1">&copy; 2018 - {{ new Date().getFullYear() }} Makler-Anfragen.immo</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1">Powered by</span>
      <a href="https://www.makler-anfragen.immo" target="_blank">makler-anfragen.immo</a>
    </div>
  </CFooter>-->
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
