<template>
  <CHeader fixed with-subheader dark>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
      v-c-emit-root-event:toggle-sidebar-mobile
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
      v-c-emit-root-event:toggle-sidebar
    />
    <CHeaderBrand
      class="mx-auto d-lg-none"
      to="/"
      src="img/brand/cloud-prt-logo.svg"
      width="190"
      height="46"
      alt="Makler-Anfragen"
    >
      <CIcon name="logo" height="48" alt="Logo"/>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact>
          Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          Settings
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="px-3">
      <CHeaderNavItem class="px-3" style="padding-right: 4px !important;">
        <CButton class="ml-1" size="sm" @click="$router.push({ name: 'PlanSettings' })">
          <strong>{{ this.$commons.getFromStorage('currentRealm').name }}</strong>
        </CButton>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <div class="position-relative" @click="toggleBellNotifications">
          <CHeaderNavLink>
            <CIcon name="cil-bell"/>
          </CHeaderNavLink>
          <CBadge v-if="bellNotificationsCount > 0" color="danger" shape="pill" class="position-absolute notification-badge">
            {{ bellNotificationsCount }}
          </CBadge>
          <div v-if="showBellNotifications" class="notification-menu">
            <div class="notification-header">
              <strong>Benachrichtigungen</strong>
              <CIcon name="cil-x" @click.stop="toggleBellNotifications" class="close-icon"/>
            </div>
            <div class="notification-summary">
              {{ unreadCount }} ungelesen | {{ highPriorityCount }} hohe Priorität
            </div>
            <div class="notification-body">
              <template v-if="Object.keys(groupedNotifications).length > 0">
                <div v-for="(notifications, priority) in groupedNotifications" :key="priority">
                  <div class="priority-header">{{ getPriorityLabel(priority) }}</div>
                  <div 
                    v-for="notification in notifications" 
                    :key="notification.id" 
                    class="notification-item"
                    :class="{ 'unread': !notification.read }"
                    :style="{ borderLeft: `4px solid ${getPriorityColor(priority)}` }"
                  >
                    <div class="notification-title">
                      {{ notification.title }}
                      <span v-if="!notification.read" class="unread-indicator"></span>
                    </div>
                    <div class="notification-time">{{ notification.time }}</div>
                    <div class="notification-description">{{ notification.description }}</div>
                    <div class="notification-actions">
                      <button
                        v-if="!notification.read"
                        class="mark-as-read-button"
                        @click.stop="markAsRead(notification.id)"
                      >
                        Als gelesen markieren
                      </button>
                    </div>
                  </div>
                </div>
              </template>
              <div v-else class="no-notifications-message">
                <CIcon name="cil-bell" size="xl" class="text-muted mb-2"/>
                <p>Aktuell liegen keine neuen Nachrichten vor.</p>
                <p>Wir informieren Sie hier über wichtige Updates und Wartungsarbeiten.</p>
              </div>
            </div>
          </div>
        </div>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <div class="position-relative" @click="toggleNotifications">
          <CHeaderNavLink>
            <CIcon name="cil-list"/>
          </CHeaderNavLink>
          <CBadge v-if="numberOfOpenTask > 0" color="danger" shape="pill" class="position-absolute notification-badge">
            {{ numberOfOpenTask }}
          </CBadge>
          <div v-if="showNotifications" class="notification-menu">
            <div class="notification-header">
              <strong>Aufgaben</strong>
              <CIcon name="cil-x" @click.stop="toggleNotifications" class="close-icon"/>
            </div>
            <div class="notification-body">
              <div v-if="openTasks.length > 0">
                <div 
                  v-for="task in openTasks" 
                  :key="task.id" 
                  class="notification-item"
                  @click="goToTask(task)"
                >
                  <div class="notification-title">{{ task.title }}</div>
                  <div class="notification-time">{{ task.startTime }} - {{ task.endTime }}</div>
                  <div class="notification-description">{{ task.description }}</div>
                </div>
              </div>
              <div v-else class="no-tasks-message">
                <CIcon name="cil-check-circle" size="xl" class="text-success mb-2"/>
                <p>Keine offenen Aufgaben vorhanden.</p>
              </div>
            </div>
          </div>
        </div>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <div class="position-relative" @click="toggleEmailNotifications">
          <CHeaderNavLink>
            <CIcon name="cil-envelope-open"/>
          </CHeaderNavLink>
          <CBadge v-if="openedEmailsCount > 0" color="danger" shape="pill" class="position-absolute notification-badge">
            {{ openedEmailsCount }}
          </CBadge>
          <div v-if="showEmailNotifications" class="notification-menu">
            <div class="notification-header">
              <strong>Geöffnete E-Mails</strong>
              <CIcon name="cil-x" @click.stop="toggleEmailNotifications" class="close-icon"/>
            </div>
            <div class="notification-body">
              <div v-if="openedEmails.length > 0">
                <div 
                  v-for="email in openedEmails" 
                  :key="email.id" 
                  class="notification-item"
                >
                  <div class="notification-title">{{ email.subject }}</div>
                  <div class="notification-time">{{ email.openedAt }}</div>
                </div>
              </div>
              <div v-else class="no-tasks-message">
                <CIcon name="cil-check-circle" size="xl" class="text-success mb-2"/>
                <p>Keine kürzlich geöffneten E-Mails.</p>
              </div>
            </div>
          </div>
        </div>
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
    <CModal
      title="Aktivität planen"
      size="lg"
      :show.sync="visibleActivityModal"
      :close-on-backdrop="false"
    >
      <ActivityWidget
        ref="activityWidget"
        :applyUpdate="applyUpdate"
        :closeModal="closeModal"
      />
      <div slot="footer">
        <CButton
          class="mr-3"
          color="dark"
          variant="outline"
          @click="visibleActivityModal = false"
        >
          Abbrechen
        </CButton>
        <CButton
          color="success"
          @click="submit"
        >
          Speichern
        </CButton>
      </div>
    </CModal>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import { CIcon, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CBreadcrumbRouter, CModal, CHeader, CHeaderNav, CHeaderNavItem, CHeaderNavLink, CHeaderBrand, CToggler, CBadge } from '@coreui/vue'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/de'
import ActivityWidget from '@/views/base/modal/ActivityWidget'
import { mapState, mapActions } from 'vuex'
import WebSocketService from '@/services/WebSocketService'
import toastPlugin from '@/toastPlugin'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    CIcon,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,
    CBreadcrumbRouter,
    CModal,
    CHeader,
    CHeaderNav,
    CHeaderNavItem,
    CHeaderNavLink,
    CHeaderBrand,
    CToggler,
    CBadge,
    ActivityWidget
  },
  data() {
    return {
      numberOfOpenTask: 0,
      openTasks: [],
      showNotifications: false,
      visibleActivityModal: false,
      selectedTaskId: null,
      openedEmailsCount: 0,
      openedEmails: [],
      showEmailNotifications: false,
      showBellNotifications: false,
      unreadNotifications: {}
    }
  },
  computed: {
    bellNotificationsCount() {
      return this.notifications.filter(notification => !notification.read).length;
    },
    ...mapState({
      notifications: state => state.notifications.notifications
    }),
    bellNotifications() {
      return this.notifications.map(notification => ({
        ...notification,
        time: moment(notification.createdAt).format('LLL')
      }));
    },
    sortedNotifications() {
      return [...this.bellNotifications].sort((a, b) => {
        const priorityOrder = { high: 3, normal: 2, low: 1 };
        if (priorityOrder[b.priority] !== priorityOrder[a.priority]) {
          return priorityOrder[b.priority] - priorityOrder[a.priority];
        }
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
    groupedNotifications() {
      return this.sortedNotifications.reduce((acc, notification) => {
        if (!acc[notification.priority]) {
          acc[notification.priority] = [];
        }
        acc[notification.priority].push(notification);
        return acc;
      }, {});
    },
    unreadCount() {
      return this.bellNotifications.filter(n => !n.read).length;
    },
    highPriorityCount() {
      return this.bellNotifications.filter(n => n.priority === 'high').length;
    }
  },
  created() {
    this.fetchNotifications();
    this.fetchOpenTasks();
    this.fetchBellNotifications();
  },
  methods: {
    ...mapActions(['fetchNotifications', 'addNotification', 'markAsRead', 'removeNotification']),
    toggleNotifications() {
      this.showNotifications = !this.showNotifications;
    },
    toggleEmailNotifications() {
      this.showEmailNotifications = !this.showEmailNotifications;
    },
    toggleBellNotifications() {
      this.showBellNotifications = !this.showBellNotifications;
    },
    fetchOpenTasks() {
      axios.get('/task/realm')
        .then(response => {
          const tasks = response.data.filter(task => !task.done)
          this.numberOfOpenTask = tasks.length
          this.openTasks = tasks.map(task => ({
            ...task,
            startTime: moment(task.startTime).format('LLL'),
            endTime: moment(task.endTime).format('LLL')
          }))
        })
        .catch(err => {
          console.log(err)
        })
    },
    goToTask(task) {
      this.selectedTaskId = task.id;
      this.visibleActivityModal = true;
      this.$refs.activityWidget.initializeWithEntity(task);
    },
    submit() {
      this.$refs.activityWidget.submit(this.selectedTaskId);
    },
    closeModal() {
      this.$refs.activityWidget.initialize();
      this.visibleActivityModal = false;
    },
    applyUpdate(data) {
      console.log('Apply update:', data);
      // Implement the update functionality if required.
    },
    handleMailOpened(leadId) {
      this.openedEmailsCount++;
      this.openedEmails.unshift({
        id: leadId,
        subject: `Email for Lead ${leadId}`,
        openedAt: moment().format('LLL')
      });
      // Limit the number of displayed emails to, for example, 10
      if (this.openedEmails.length > 10) {
        this.openedEmails.pop();
      }
    },
    handleNewNotification(notificationData) {
      console.log('New notification received:', notificationData);
      const formattedNotification = {
        id: notificationData.id,
        title: notificationData.title,
        time: moment(notificationData.createdAt).format('LLL'),
        description: notificationData.description,
        read: notificationData.read || false,
        priority: notificationData.priority || 'normal'
      };

      const updatedNotifications = [formattedNotification, ...this.notifications];
      if (updatedNotifications.length > 10) {
        updatedNotifications.pop();
      }
      this.$store.commit('SET_NOTIFICATIONS', updatedNotifications);
      this.$set(this.unreadNotifications, formattedNotification.id, true);
      this.showBrowserNotification(formattedNotification);
    },
    showBrowserNotification(notification) {
      if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            new Notification(notification.title, {
              body: notification.description
            });
          }
        });
      }
    },
    markAsRead(notificationId) {
      axios.put(`/api/notifications/${notificationId}/read`)
        .then(response => {
          if (response.status === 200) {
            this.$store.commit('MARK_AS_READ', notificationId);
            toastPlugin.makeToast('Erfolg', 'Benachrichtigung als gelesen markiert.');
          }
        })
        .catch(err => {
          console.error('Error marking notification as read:', err);
          toastPlugin.makeToast('Fehler', 'Fehler beim Markieren der Benachrichtigung als gelesen.');
        });
    },
    fetchBellNotifications() {
      axios.get('/api/notifications')
        .then(response => {
          if (Array.isArray(response.data)) {
            const notifications = response.data.map(notification => ({
              id: notification.id,
              title: notification.title,
              time: moment(notification.createdAt).format('LLL'),
              description: notification.description,
              read: notification.read || false,
              priority: notification.priority || 'normal'
            }));
            this.$store.commit('SET_NOTIFICATIONS', notifications);
            this.unreadNotifications = notifications.reduce((acc, n) => {
              if (!n.read) {
                acc[n.id] = true;
              }
              return acc;
            }, {});
          } else {
            console.error('Expected response.data to be an array, but got:', response.data);
          }
        })
        .catch(err => {
          console.error('Error fetching notifications:', err);
          toastPlugin.makeToast('Fehler', 'Fehler beim Abrufen der Benachrichtigungen.');
        });
    },
    disconnectWebSocket() {
      if (this.stompClient) {
        this.stompClient.disconnect();
      }
    },
    getPriorityColor(priority) {
      switch(priority) {
        case 'high': return '#FF4136';
        case 'normal': return '#FFDC00';
        case 'low': return '#2ECC40';
        default: return '#AAAAAA';
      }
    },
    getPriorityLabel(priority) {
      switch(priority) {
        case 'high': return 'Hohe Priorität';
        case 'normal': return 'Normale Priorität';
        case 'low': return 'Niedrige Priorität';
        default: return 'Unbekannte Priorität';
      }
    }
  },
  mounted() {
    console.log('TheHeader component mounted');
    WebSocketService.connect();
    console.log('WebSocketService connected');
  },
  beforeDestroy() {
    WebSocketService.disconnect();
  }
}
</script>




<style scoped>
.mark-as-read-button {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  color: #495057;
  font-size: 0.75em;
  padding: 2px 6px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 5px;
}

.mark-as-read-button:hover {
  background-color: #e9ecef;
  border-color: #adb5bd;
  color: #212529;
}


.notification-badge {
  top: -5px;
  right: -5px;
}

.notification-menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 9999;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: rgb(232 234 238) !important;
}

.close-icon {
  cursor: pointer;
}

.notification-body {
  max-height: 300px;
  overflow-y: auto;
}

.notification-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.notification-item:hover {
  background-color: #f5f5f5;
}

.notification-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.notification-time {
  font-size: 0.8em;
  color: #666;
  margin-bottom: 5px;
}

.no-tasks-message,
.no-notifications-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  color: #6c757d;
}

.no-tasks-message p,
.no-notifications-message p {
  margin-top: 10px;
  font-size: 0.9em;
}
.notification-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.notification-item.unread {
  background-color: #f0f0f0;
}

.notification-item:hover {
  background-color: #f5f5f5;
}

.unread-indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #007bff;
  margin-left: 5px;
}

.priority-header {
  font-weight: bold;
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #eee;
}

.notification-summary {
  padding: 10px;
  background-color: #e9ecef;
  border-bottom: 1px solid #ddd;
  font-size: 0.9em;
  color: #495057;
}
</style>