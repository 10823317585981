import commons from '@/commons'

export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Leads',
        to: '/leads',
        icon: 'cil-graph',
      },
      commons.isSystemAdmin() ? {
        _name: 'CSidebarNavTitle',
        _children: ['Administration']
      } : {},
      commons.isSystemAdmin() ? {
        _name: 'CSidebarNavItem',
        name: 'Organisationen',
        to: '/admin/organizations',
        icon: 'cil-building'
      } : {},
      commons.isSystemAdmin() ? {
        _name: 'CSidebarNavItem',
        name: 'Instanzen',
        to: '/admin/realms',
        icon: 'cil-address-book'
      } : {},
      commons.isSystemAdmin() ? {
        _name: 'CSidebarNavItem',
        name: 'Benutzer',
        to: '/admin/users',
        icon: 'cil-user'
      } : {},
      commons.isSystemAdmin() ? {
        _name: 'CSidebarNavItem',
        name: 'Benachrichtungen',
        to: '/admin/notifications',
        icon: 'cil-x'
      } : {},
      {
        _name: 'CSidebarNavTitle',
        _children: ['Einstellungen']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Grundeinstellungen',
        to: '/settings/general',
        icon: 'cil-building',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Zielgruppen',
        to: '/emailBasic/groups',
        icon: 'cil-people',
      },
      commons.isOrganizationAdmin() ? {
        _name: 'CSidebarNavItem',
        name: 'Benutzer',
        to: '/users',
        icon: 'cil-user'
      } : {},
      commons.hasRealmPlanAndRole('KENSINGTON', 'ORGANIZATION_ADMIN') ? {
        _name: 'CSidebarNavDropdown',
        name: 'Standortverwaltung',
        route: '/kensington-locations',
        icon: 'cil-location-pin',
        badge: {
          color: 'primary',
          text: 'NEU'
        },
        items: [
          {
            name: 'Standorte',
            to: '/kensington-locations',
          },
          {
            name: 'Importieren',
            to: '/kensington-locations-import'
          },
        ]
      } : {},
            {
              _name: 'CSidebarNavTitle',
              _children: ['Immobilienbewertung'],
            },
            {
              _name: 'CSidebarNavItem',
              name: 'Freigaben',
              to: '/queuedLeads',
              icon: 'cil-book',
            },
            {
              _name: 'CSidebarNavItem',
              name: 'Wertermittlungstool',
              to: '/iframe',
              icon: 'cil-browser',
            },
            {
              _name: 'CSidebarNavItem',
              name: 'Wohnmarktanalyse',
              to: '/pdf-settings',
              icon: 'cil-file',
            },
            /* {
              _name: 'CSidebarNavItem',
              name: 'Chatbot',
              to: '/chatbot-settings',
              icon: 'cib-probot'
            }, */
      {
        _name: 'CSidebarNavTitle',
        _children: ['Social Media']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Hilfe',
        to: '/socialMedia/hilfe',
        icon: 'cil-lightbulb',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Beiträge',
        to: '/socialMedia',
        icon: 'cil-thumb-up',
        badge: {
          color: 'primary',
          text: 'NEU'
        },
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Social Media Konten',
        to: '/socialMedia/connections',
        icon: 'cil-swap-horizontal',
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Lead Funnel']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Funnel',
        to: '/leadGenerator',
        icon: 'cil-window-restore',
      },

      {
        _name: 'CSidebarNavTitle',
        _children: ['E-Mail Marketing']
      },

      {
        _name: 'CSidebarNavItem',
        name: 'E-Mail Funnels',
        to: '/funnel',
        icon: 'cil-filter',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'E-Mail Newsletter',
        to: '/newsletter/article',
        icon: 'cil-newspaper',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'E-Mail Abonnenten',
        to: '/emailBasic/subscriber',
        icon: 'cil-newspaper',
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'E-Mail Grundeinstellungen',
        route: '/emailBasic',
        icon: 'cil-settings',
        items: [
          {
            name: 'Anmeldeformulare',
            to: '/emailBasic/generator'
          },
          {
            name: 'Opt-In',
            to: '/emailBasic/opt-in'
          },
        ]
      },

      {
        _name: 'CSidebarNavTitle',
        _children: ['Landingpage']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Landingpages',
        to: '/landingpage',
        icon: 'cil-sitemap',
      },

      {
        _name: 'CSidebarNavTitle',
        _children: ['CRM-System']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Pipelines',
        to: '/deals/pipeline',
        icon: 'cil-chart',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Deals',
        to: '/deals/index',
        icon: 'cil-chart-line',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Aufgaben',
        to: '/tasks',
        icon: 'cil-task',
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Kontakte',
        route: '/contacts',
        icon: 'cil-contact',
        badge: {
          color: 'primary',
          text: 'NEU'
        },
        items: [
          {
            name: 'Personen',
            to: '/contacts/people'
          },
          {
            name: 'Organisationen',
            to: '/contacts/organization'
          },
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Import',
        to: '/settings/import',
        icon: 'cil-cloud-upload',
      },

      {
        _name: 'CSidebarNavTitle',
        _children: ['Account']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Statistiken',
        to: '/statistics',
        icon: 'cil-bar-chart'
      },
/*
      {
        _name: 'CSidebarNavItem',
        name: 'Tarif & Rechnungen',
        to: '/settings/plan',
        icon: 'cil-cloud-upload',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Support',
        to: '/form',
        icon: 'cil-life-ring',
      }*/
    ]
  }
]