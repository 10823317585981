<!--suppress JSUnfilteredForInLoop -->
<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show.sync="show"
  >
    <CSidebarBrand to="/">
      <CImg
          class="c-sidebar-brand-full"
          :src="computedLogoUrl"
          width="160" height="160" alt="LeadHunter"/>
    </CSidebarBrand>
    <CRenderFunction flat :content-to-render="nav"/>
    <button class="c-sidebar-minimizer" type="button" @click="openRealmSwitcher">
      <span class="prt-realm-switcher">{{ currentRealm.name }}</span>
      <span v-if="otherRealms.length" class="minimizer"/>
    </button>
    <div class="otherRealms" :style="calculatedStyles">
      <template v-for="realm in otherRealms">
        <button class="c-sidebar-minimizer" type="button" @click="realmSwitch(realm)">
          <span class="prt-realm-switcher">{{ realm.name }}</span>
        </button>
      </template>
    </div>
  </CSidebar>
</template>

<script>
import nav from './_nav'
import navKensington from './_nav_kensington'
import axios from 'axios'
import commons from '@/commons'

export default {
  name: 'TheSidebar',
  data () {
    return {
      minimize: false,
      nav: commons.hasRealmPlan('KENSINGTON') && !commons.isOrganizationAdmin() ? navKensington : nav,
      show: 'responsive',
      otherRealms: [],
      showRealmSwitcher: false,
      calculatedStyles: '',
      currentRealm: commons.getFromStorage('currentRealm'),
      numberOfOpenTask: null,
    }
  },
  mounted () {
    this.$root.$on('toggle-sidebar', () => {
      const sidebarOpened = this.show === true || this.show === 'responsive'
      this.show = sidebarOpened ? false : 'responsive'
    })
    this.$root.$on('toggle-sidebar-mobile', () => {
      const sidebarClosed = this.show === 'responsive' || this.show === false
      this.show = sidebarClosed ? true : 'responsive'
    })
    axios.get('/user/allowedRealms')
      .then(response => {
        let otherRealms = []
        for (const realm of response.data) {
          if (realm.id !== this.currentRealm.id) {
            otherRealms.push(realm)
          }
        }
        this.otherRealms = otherRealms
        console.log(otherRealms)
      })
      .catch(err => {
        this.$toaster.makeToast('Fehler!', 'Erlaubte Realms konnten nicht abgerufen werden.')
        console.error(err)
      })

    axios.get('/task/open')
      .then(response => {
        this.numberOfOpenTask = response.data && response.data.count
        const menuItem = document.querySelector('[href="#/tasks"]')
        const badge = document.createElement('span')
        badge.style.marginLeft = '10px'
        badge.setAttribute('class', 'badge badge-danger')
        if (this.numberOfOpenTask > 0) {
          badge.innerHTML = this.numberOfOpenTask
        }
        menuItem.insertBefore(badge, menuItem.children[1])
      })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    realmSwitch (realm) {
      this.$realmSwitcherModal.showModal(realm.name)
      commons.setToStorage('realmContext', realm.id)
      commons.setToStorage('currentRealm', realm)
      commons.setToStorage('license', realm.license)
      window.location.reload();
    },
    calculateStyleRealms () {
      let height = 'height: '
      if (this.showRealmSwitcher) {
        height = this.otherRealms.length > 3 ? '150px' : `${this.otherRealms.length * 50}px`
      } else {
        height = '0'
      }
      this.calculatedStyles = 'height: ' + height
    },
    openRealmSwitcher () {
      this.showRealmSwitcher = !this.showRealmSwitcher
      this.calculateStyleRealms()
    }
  },
  computed: {
    computedLogoUrl() {
      return this.currentRealm.organization && this.currentRealm.organization.logoUrl
        ? this.currentRealm.organization.logoUrl
        : 'img/brand/marketingcloud-logo.svg'
    }
  }
}
</script>

<style lang="scss">
  .c-sidebar-minimizer::before {
    display: none;
  }
  .minimizer {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%238a93a2' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
    display: block;
    width: 50px;
    height: 50px;
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12.5px;
    -webkit-transition: 0.25s;
    transition: 0.25s;
    flex: 0 0 0;
    min-height: 50px;
    min-width: 50px;
    transform: rotate(-90deg);
  }
  .prt-realm-switcher {
    height: 50px;
    display: flex;
    text-align: left;
    justify-content: left;
    align-items: center;
    line-height: 1em;
    color: white;
    width: 100%;
    padding-left: 25px;
  }
  .otherRealms {
    max-height: 150px;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    transition: all 0.3s;
    height: 0;

    .c-sidebar-minimizer {
      background-color: #2b3438;
    }
    .c-sidebar {
      background: #2b3438 !important;
    }
  }
</style>
