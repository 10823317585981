import commons from '@/commons'

export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Leads',
        to: '/leads',
        icon: 'cil-graph',
      },
      commons.isSystemAdmin() ? {
        _name: 'CSidebarNavTitle',
        _children: ['Administration']
      } : {},
      commons.isSystemAdmin() ? {
        _name: 'CSidebarNavItem',
        name: 'Organisationen',
        to: '/admin/organizations',
        icon: 'cil-building'
      } : {},
      commons.isSystemAdmin() ? {
        _name: 'CSidebarNavItem',
        name: 'Instanzen',
        to: '/admin/realms',
        icon: 'cil-address-book'
      } : {},
      commons.isSystemAdmin() ? {
        _name: 'CSidebarNavItem',
        name: 'Benutzer',
        to: '/admin/users',
        icon: 'cil-user'
      } : {},

      commons.isAnyAdmin() ? {
        _name: 'CSidebarNavTitle',
        _children: ['Einstellungen']
      } : {},
      commons.isAnyAdmin() ? {
        _name: 'CSidebarNavItem',
        name: 'Grundeinstellungen',
        to: '/settings/general',
        icon: 'cil-building',
      } : {},
      commons.isAnyAdmin() ? {
        _name: 'CSidebarNavItem',
        name: 'Benutzer',
        to: '/users',
        icon: 'cil-user'
      } : {},
      commons.hasRealmPlanAndRole('KENSINGTON', 'ORGANIZATION_ADMIN') ? {
        _name: 'CSidebarNavDropdown',
        name: 'Standortverwaltung',
        route: '/kensington-locations',
        icon: 'cil-location-pin',
        badge: {
          color: 'primary',
          text: 'NEU'
        },
        items: [
          {
            name: 'Standorte',
            to: '/kensington-locations',
          },
          {
            name: 'Importieren',
            to: '/kensington-locations-import'
          },
        ]
      } : {},

      {
        _name: 'CSidebarNavTitle',
        _children: ['Immobilienbewertung']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Freizugebene Leads',
        to: '/queuedLeads',
        icon: 'cil-book',
      },
      commons.isRealmAdmin() ? {
        _name: 'CSidebarNavItem',
        name: 'Wertermittlungstool',
        to: '/iframe',
        icon: 'cil-browser',
      } : {},
      commons.isRealmAdmin() ? {
        _name: 'CSidebarNavItem',
        name: 'Wohnmarktanalyse',
        to: '/pdf-settings',
        icon: 'cil-file',
      } : {},
      commons.isRealmAdmin() ? {
        _name: 'CSidebarNavItem',
        name: 'Statistiken',
        to: '/statistics',
        icon: 'cil-bar-chart'
      } : {}
    ]
  }
]
